import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { rtypeN16r, rtypeT21sb, typeN16r, typeT21sb } from '../../styles/typography/presets';
import { MoreLink } from './MoreLink';
import moment from 'moment';
import { breakpoints } from '../../styles/layout';
import FluidImg from './FluidImg';


const ImageContainer = styled.div`

`;

const Image = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  border-radius: 6px;
  
  ${ImageContainer} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    > .gatsby-image-wrapper {
      height: 100%;
    }
    
    > img {
      height: 100%;
    }
  }
`;

const Title = styled.div`
  ${rtypeT21sb};
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Date = styled.div`
  ${rtypeN16r};
`;

const Description = styled.div`
  ${rtypeN16r};
`;

const ReadMore = styled(MoreLink)``;

const Container = styled.div`
  ${Image} {
    margin-bottom: 21px;
  }
  
  ${Date} {
    margin-bottom: 18px;
  }
  
  ${Description} {
    margin-bottom: 18px;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    ${Image} {
      margin-bottom: 30px;
    }
    
    ${Date} {
      margin-bottom: 24px;
    }
    
    ${Description} {
      margin-bottom: 24px;
    }
  }
`;

const PostSnippet = ({ image, date, title, description, link, ...props }) => (
  <Container>
    <Image>
      <ImageContainer>
        <Link to={link}>
          {image.localFile ? (
            <FluidImg
              image={image}
              alt={image.alt}
            />
          ) : (
            <img
              src={image.url}
              alt={image.alt}
            />
          )}
        </Link>
      </ImageContainer>
    </Image>
    <Date>{moment(date).format('D MMMM YYYY')}</Date>
    <Title>
      <Link to={link}>
        {title}
      </Link>
    </Title>
    <Description>{description}</Description>
    <ReadMore to={link}>Read more</ReadMore>
  </Container>
);

export default PostSnippet;
