import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import { rtypeT21sb, typeN14r, typeN16r, typeT21sb, typeT38sb } from '../../styles/typography/presets';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { PurpleOutlineButton } from '../buttons/index';
import { colors } from '../../styles/colors';
import Footnote from './Footnote';
import routes from '../../routes';
import { TextField } from '../forms';
import { DarkBlueOutlineButton, WhiteOutlineButton } from '../buttons';
import fetch from 'cross-fetch';
import { webhooks } from '../../webhooks';
import EmailForm from './EmailForm';


const Title = styled.div`
  ${typeT21sb};
  max-width: 474px;
`;

const Brief = styled.div`
  ${typeN14r};
  max-width: 474px;
`;

const CustomEmailForm = styled(EmailForm)``;

const CustomFootnote = styled(Footnote)``;

const CustomContainer = styled(Container)`
  color: ${props => props.overlay ? colors.white : colors.darkBlue};
  grid-template-rows: 90px auto auto auto 116px auto;
  
  background-image: url("${props => props.background}");
  background-color: ${colors.beige};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top: 1px solid ${colors.grey};
    
  position: relative;
  &:before {
    content: '';
    display: ${props => props.overlay ? 'block' : 'none'};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, ${colors.purple}, transparent 60%);
    z-index: 0;
  }
  
  ${Title} {
    grid-column: 2 / span 7;
    grid-row: 2;
    margin-bottom: 12px;
    z-index: 1;
  }
  ${Brief} {
    grid-column: 2 / span 7;
    grid-row: 3;
    margin-bottom: 27px;
    z-index: 1;
  }
  ${CustomEmailForm} {
    grid-column: 2 / span 8;
    grid-row: 4;
    z-index: 1;
  }
  ${CustomFootnote} {
    grid-column: 1 / span 10;
    grid-row: 6;
    z-index: 1;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    background-image: url("${props => props.background}");
    
    ${Title} {
      ${typeT38sb};
      grid-column: 2 / span 3;
    }
    ${Brief} {
      ${typeN16r};
      grid-column: 2 / span 3;
      margin-bottom: 54px;
    }
    ${CustomEmailForm} {
      grid-column: 2 / span 3;
    }
  }
`;


class ContactSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
    };
  }

  render() {
    const {
      background, title, brief, footnote, overlay, white, ...props
    } = this.props;

    return (
      <CustomContainer background={background} {...props} overlay={overlay}>
        <Title>{title}</Title>
        <Brief>{brief}</Brief>
        <CustomEmailForm
          webhook={webhooks.emailNewsletter}
          white={white}
        />
        <CustomFootnote
          message={footnote}
          buttonText={'Speak with us'}
          to={routes.contact}
        />
      </CustomContainer>
    );
  }
}


export default ContactSection;