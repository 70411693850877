import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import PostSnippet from '../shared/PostSnippet';
import routes from '../../routes';
import CategorySelector from './CategorySelector';
import moment from 'moment';
import * as qs from 'qs';


const Post = styled.li`

`;

const Posts = styled.ul`
  ${Post} {
    margin-bottom: 90px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media(min-width: ${breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 75px;
    grid-row-gap: 120px;
    
    ${Post} {
      margin-bottom: 0;
    }
  }
`;

const CustomCategorySelector = styled(CategorySelector)`
`;


const CustomContainer = styled(Container)`
  ${CustomCategorySelector} {
    text-align: center;
    grid-column: 3 / span 6;
    padding: 48px 0 0;
  }
  ${Posts} {
    padding: 90px 0;
    grid-column: 2 / span 8;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    ${CustomCategorySelector} {
      padding: 150px 0 0;
    }
    ${Posts} {
      padding: 120px 0;
    }
  }
`;

class PostsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: null,
    };
  }

  componentDidMount() {
    this.updateFromUrl();
  }

  updateFromUrl = () => {
    const { category } = qs.parse(window.location.search.substring(1));
    this.setState({
      category: category,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { category } = qs.parse(window.location.search.substring(1));
    if ((prevState.category !== this.state.category) || (category !== this.state.category)) {
      this.updateFromUrl();
    }
  }

  categoryClicked = (category) => {
    this.setState({
      category: category,
    });
    if (category) {
      window.history.pushState(category, '', `${routes.journal}?${qs.stringify({ category: category })}`);
    } else {
      window.history.pushState(category, '', routes.journal);
    }
  };

  render() {
    const categories = Array.from(new Set(this.props.posts.map(post => post.data.category))).sort((a, b) => (a < b) ? -1 : 1);

    const posts = this.props.posts.filter(post => {
      if (!this.state.category) return true;
      return post.data.category === this.state.category;
    });

    return (
      <CustomContainer>
        <CustomCategorySelector
          categories={categories}
          categoryClicked={this.categoryClicked}
          selected={this.state.category}
        />
        <Posts>
          {posts.sort((a, b) => moment(b.data.date) - moment(a.data.date)).map((post, index) => (
            <Post key={post.data.title}>
              <PostSnippet
                title={post.data.title}
                date={post.data.date}
                description={post.data.description}
                image={post.data.image}
                link={`${routes.journal}/${post.uid}`}
              />
            </Post>
          ))}
        </Posts>
      </CustomContainer>
    );
  }
}

export default PostsSection;
