import React from 'react';
import Layout from '../components/layouts';
import { graphql } from 'gatsby';
import Hero from '../components/shared/Hero';
import PostsSection from '../components/blog/PostsSection';
import { Helmet } from 'react-helmet';
import { descriptionTags, imageTags, titleTags } from '../utilities/head-tags';
import SubscribeSection from '../components/shared/ContactSection';


class JournalPage extends React.Component {
  componentDidMount() {
    window.analytics && window.analytics.page("Journal", {
      title: `Journal | ${this.props.data.prismicSite.data.name}`,
      url: window.location.href,
      path: window.location.pathname,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  render() {
    const props = this.props;
    const pageData = props.data.prismicBlogPage.data;
    const posts = props.data.allPrismicBlogPost.edges.map(edge => edge.node);

    return (
      <Layout reverseLogo>
        <Helmet>
          {titleTags(`Journal | ${props.data.prismicSite.data.name}`)}
          {descriptionTags(pageData.hero_brief.text)}
          {imageTags(pageData.hero_image.url)}
        </Helmet>
        <Hero
          image={pageData.hero_image}
          message={pageData.hero_message}
          brief={pageData.hero_brief.html}
          title={pageData.hero_title}
        />
        <PostsSection posts={posts}/>
        <SubscribeSection
          background={pageData.subscription_background.url}
          title={pageData.subscription_title}
          brief={pageData.subscription_message}
          footnote={pageData.subscription_footnote}
        />
      </Layout>
    );
  }
}

export default JournalPage;

export const query = graphql`
  query {
    prismicSite {
      data {
        name
      }
    }
    prismicBlogPage {
      data {
        hero_title
        hero_message
        hero_brief {
          html
          text
        }
        hero_image {
          alt
          copyright
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
              
            }
          }
        }
        subscription_title
        subscription_message
        subscription_footnote
        subscription_background {
          alt
          copyright
          url
        }
      }
    }
    allPrismicBlogPost {
      edges {
        node {
          uid
          dataString
          data {
            date
            title
            description
            category
            image {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;