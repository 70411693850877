import React from 'react';
import styled from 'styled-components';
import { rtypeN38r } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import { breakpoints } from '../../styles/layout';
import * as pluralize from 'pluralize';


const Category = styled.div`
  color: ${props => props.selected ? colors.darkBlue : colors.grey};
  padding-bottom: 6px;
  border-bottom: 2px solid ${props => props.selected ? colors.orange : colors.grey};
  ${rtypeN38r};
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: -15px;
  
  ${Category} {
    margin: 15px;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    margin: -30px;
    
    ${Category} {
      margin: 30px;
    }
  }
  
`;

export default ({categories, selected, categoryClicked, ...props}) => (
  <Container {...props}>
    <Category
      onClick={() => categoryClicked(null)}
      selected={!selected}>All Posts</Category>
    {categories.map(category => {
      if (pluralize(category) === 'Prayer Diaries') return null;
      return (
        <Category
          onClick={() => categoryClicked(category)}
          selected={selected === category}
          key={category}>{pluralize(category)}</Category>
        )
    })}
  </Container>
);
